/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2023-01-03 16:34:13
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2023-01-03 17:18:41
 * @FilePath: /PPTist/src/utils/download.ts
 */
import axios from 'axios'

export const downloadRemoteJson = async (url: string) => {
  return await axios
    .get(url)
    .then(res => {
      if (res.status === 200 && res.statusText === 'OK') {
        return res.data
      }
    })
}

export const getFileNameFormURL = (url: string): string => {
  return url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.')) 
}